body {
  margin: 0;
  height: 100vh;
}

.App {
  height: 100vh;
  display: grid;
  place-items: center;
}

.App > * {
  grid-area: 1/1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(8, 115, 131);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  pointer-events: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.5;
}

.form > * {
  /* pointer-events: auto; */
  position: absolute;
  bottom: 1em;
  left: 1em;
  display: flex;
  flex-direction: column;

  background: #fff;
  padding: 1em;
  border: 2px solid #abc;
  border-radius: 1em / 0.5em;
  box-shadow: 0 8px 16px #0000003f;
}

textarea {
  border: 1px solid #abc;
}